@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.background-curtain.background-curtain-leave:before {
  opacity: 0;
}

.background-curtain:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  z-index: 100;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6) !important;
  transition: opacity 0.5s ease;
  animation: curtain 0.3s ease;
}

@keyframes curtain {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
