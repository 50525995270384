@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-history {
  &__contact-us {
    margin-top: $rew-pane-vertical-margin;
  }

  &__graph {
    margin-top: 13px;
  }
}

.rew-history__inner-page {
  max-width: 767px;
  margin: 13px auto 0;
}

.rew-page__rew-history.rew-page__rew-history {
  max-width: initial;
}
