@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.debit-card-mailing-address {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: auto;

  @include not-phone {
    width: 90%;
  }

  autocomplete-mailing-address {
    height: 100%;
  }

  mailing-address-form {
    height: 100%;
  }
}
