@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.load-money {
  &__welcome {
    margin-top: 9px;
  }

  &__card {
    margin-top: 18px;
  }
}

.load-money__inner-page {
  max-width: 767px;
  margin: 13px auto 0;
}

.rew-page__load-money.rew-page__load-money {
  max-width: initial;
}

.rew-page__load-money.rew-page__load-money-new {
  margin-left: 15px;
  margin-right: 15px;
}
