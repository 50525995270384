@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.order-details__button {
  margin: 10px auto 0;
  display: block;
}

.order-details__back-button {
  margin-top: 5px;
  margin-bottom: -7px;
  display: block;
}
