@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.debit-card-activated {
  &__img {
    background-image: url(./card-activated-new.svg);
    display: inline-block;
    width: 193px;
    height: 160px;
  }
}
