@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$kyc-proof-of-address-vertical-padding: 8px;

.kyc-proof-of-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__img {
    margin: 15px auto;
    max-width: 260px;

    .order-debit-card-modal & {
      @include large-phone {
        max-width: none;
      }
    }
  }

  &__title {
    margin-top: $kyc-proof-of-address-vertical-padding;
    margin-bottom: 10px;
  }

  &__form {
    flex-grow: 1;
    margin-bottom: $kyc-proof-of-address-vertical-padding;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  &__list li {
    display: list-item;
    list-style-type: none;

    &:before {
      content: '•';
      margin-right: 8px;
    }
  }

  & &__text {
    * {
      font-size: 12px;
      line-height: 12px;
      display: block;
    }

    @include large-phone {
      * {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
