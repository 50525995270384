@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-history-list {
  &__empty-state-containter {
    margin-top: $rew-pane-vertical-margin;
  }

  &__content-containter {
    padding: 5px;
  }

  &__content-containter__img-container {
    margin-top: 10px;
    text-align: center;
  }
  &__content-containter__img-container__img {
    width: 40%;
    max-width: 160px; // for desktops
  }
}
