@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.popup-container.kyc-experiment-modal {
  background-color: #f9f9f9;

  @media screen and (max-width: 600px) {
    background-color: white;
  }

  &.active .popup {
    box-shadow: 0 2px 16px 0 rgba(198, 198, 198, 0.5);

    @media screen and (max-width: 600px) {
      box-shadow: none;
    }
  }

  & .popup {
    min-height: 496px;
    height: 550px;
    max-height: none;
  }
}

.popup-container.internal-kyc-flow {
  .popup-body {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &.rew-modal.popup-container .popup {
    width: 100%;
    padding: 0;
    max-width: none;
    border-radius: 0;
  }

  & .popup {
    height: 100%;
    width: 100%;
    max-height: none;
  }
}
