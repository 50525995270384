@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
@import '../../../assets/styles/_breakpoints.scss';

.tab-item .icon {
  display: none !important;
}

.bar-dark.rewire-nav-bar {
  border: 0;
}

.rewire-nav-bar .bar.bar-dark {
  border: 0;
}

// Olark button is already hidden by using `liveChatSvc.hideLiveChatButton()`, but just incase
.olark-wrapper {
  display: none;
}

.onfido-modal-wrapper {
  z-index: 9;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  @media only screen and (min-width: 480px) {
    justify-content: space-around;
  }
}

.onfido-inner-wrapper {
  position: relative;
  margin: auto;
  @media only screen and (max-width: 479px) {
    position: static;
    margin: 0;
  }
}

.kyc-flow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.onfido-close-button {
  width: 32px;
  height: 32px;
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 10.05 10.04"%3E%3Cpath d="M10.05.86A.24.24 0 0 0 10 .69Q9.56.28 9.37.07a.25.25 0 0 0-.37 0L5.2 3.88a.22.22 0 0 1-.35 0L1 .07A.28.28 0 0 0 .86 0a.28.28 0 0 0-.17.07L.07.69A.24.24 0 0 0 0 .86.22.22 0 0 0 .07 1L3.9 4.85A.22.22 0 0 1 4 5a.24.24 0 0 1-.07.18L.07 9a.22.22 0 0 0-.07.18.24.24 0 0 0 .07.18q.42.4.62.62a.28.28 0 0 0 .17.02.28.28 0 0 0 .14 0l3.85-3.85a.22.22 0 0 1 .35 0L9 10a.25.25 0 0 0 .35 0l.65-.65a.24.24 0 0 0 .07-.18A.22.22 0 0 0 10 9L6.15 5.19A.24.24 0 0 1 6.08 5a.22.22 0 0 1 .07-.16L10 1a.19.19 0 0 0 .07-.16z"/%3E%3C/svg%3E');
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  &.inject {
    float: right;
  }
  &.on-top {
    top: 16px;
    right: 16px;
    position: absolute;
    z-index: 2;
    @media only screen and (max-width: 479px) {
      top: 10px;
      right: 16px;
    }
  }
}

.onfido-modal {
  & > * {
    z-index: 1;
  }
}

invite-friends {
  > * {
    @include phone {
      animation: slideIn 0.75s;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
