@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.kyc-onfido {
  text-align: center;
  overflow: hidden;

  &__img {
    display: block;
    margin: 58px auto 0;
    width: 151px;
    height: 150px;
    background-image: url(./kyc-illustration.svg);

    &--with-terms {
      width: 178px;
      height: 136px;
      background-size: 100% 100%;
      background-image: url(./kyc-illustration.svg);
    }
  }

  &__sub-title {
    display: block;
    line-height: normal;
    padding-top: 18px;
    font-size: 14px;
  }

  &__text {
    display: block;
    line-height: normal;
    padding-top: 15px;
    font-size: 12px;
  }

  &__approve-terms-text {
    display: block;
    text-align: center;
  }

  &__form {
    display: block;
    margin-top: 26px;
  }

  &__approve-terms-text {
    margin-top: 55px;
    display: block;
  }

  &__text {
    color: $rew-another-gray;
    font-size: $rew-font-size-10;
    line-height: 12px;

    strong {
      font-weight: bold;
    }
  }

  &__submit-btn {
    display: block;
    text-align: center;
    margin-top: 15px;
    position: absolute;
    width: 100%;
    bottom: 0;

    &--with-terms {
      bottom: 65px;
    }
  }

  &__no-id-link {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
