@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-home {
  display: flex;
  flex-direction: column;

  &__contact-us,
  &__order-summary,
  &__deposit-card {
    margin-top: $rew-pane-vertical-margin;
  }

  &__load-wallet-btn {
    position: fixed;
    right: 12px;
    bottom: 16px;

    @media print {
      display: none;
    }

    @include not-phone {
      display: none;
    }
  }

  .animate-hide-loader.ng-hide,
  .animate-show-hide.ng-hide {
    opacity: 0;
  }
  .animate-hide-loader.ng-show,
  .animate-show-hide.ng-show {
    opacity: 1;
  }

  .animate-hide-loader.ng-hide-add,
  .animate-show-hide.ng-hide-add,
  .animate-show-hide.ng-hide-remove {
    transition: opacity ease-out 0.4s 0.5s;
  }
}

.rew-home__empty {
  margin-top: $rew-pane-vertical-margin;
}

.rew-home__empty-img {
  background-image: url('./send-money.svg');
  height: 120px;
}

.rew-home__load-first {
  &#{&} {
    padding-bottom: 5px;
  }
}

.rew-home__first-load-img {
  background-image: url('./home-load-illustration-new.svg');
}

.rew-home-orders {
  flex-basis: 100%;
}

.rew-home__element {
  margin-top: $rew-pane-vertical-margin;
  padding-top: 22px;
  padding-bottom: 17px;
}

.rew-home__contact-us,
.rew-home__card,
.rew-home__element {
  &.ng-hide {
    transform: scale(0.9);
    opacity: 0;
  }

  &.ng-show {
    transform: scale(1);
    opacity: 1;
  }

  &.ng-hide-remove {
    transition: all ease-out 0.4s 0.5s;
  }
}

.rew-home__card {
  margin-top: 9px;
  display: block;
}

.rew-home__incoming-money-transfers {
  margin-top: 9px;
  display: block;
}

.rew-home__inner-page {
  max-width: 767px;
  margin: 0 auto;
}
