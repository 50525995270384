@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
// App wide styles

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  // font-size: 100%;
  // font-weight: normal;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: 'rewire-icons';

  // see: https://css-tricks.com/snippets/css/using-font-face/
  src: url('../assets/fonts/rewire-icons2.eot'); /* IE9 Compat Modes */
  src: // url('../assets/fonts/rewire-icons2.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       // url('../assets/fonts/rewire-icons2.woff2') format('woff2'), /* Super Modern Browsers */
       // url('../assets/fonts/rewire-icons2.woff') format('woff'), /* Pretty Modern Browsers */
    url('../assets/fonts/rewire-icons2.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/rewire-icons2.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../assets/fonts/gilroy/Gilroy-Regular.eot');
  src: url('../assets/fonts/gilroy/Gilroy-Regular.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../assets/fonts/gilroy/Gilroy-Bold.eot');
  src: url('../assets/fonts/gilroy/Gilroy-Bold.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../assets/fonts/gilroy/Gilroy-Light.eot');
  src: url('../assets/fonts/gilroy/Gilroy-Light.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-Light.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Semi-Bold';
  src: url('../assets/fonts/gilroy/Gilroy-SemiBold.eot');
  src: url('../assets/fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Extra-Bold';
  src: url('../assets/fonts/gilroy/Gilroy-ExtraBold.eot');
  src: url('../assets/fonts/gilroy/Gilroy-ExtraBold.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../assets/fonts/gilroy/Gilroy-Medium.eot');
  src: url('../assets/fonts/gilroy/Gilroy-Medium.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Thin';
  src: url('../assets/fonts/gilroy/Gilroy-Thin.eot');
  src: url('../assets/fonts/gilroy/Gilroy-Thin.ttf') format('truetype'),
    url('../assets/fonts/gilroy/Gilroy-Thin.woff') format('woff'),
    url('../assets/fonts/gilroy/Gilroy-Thin.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Libre-Barcode-EAN13-Text';
  src: url('../assets/fonts/LibreBarcodeEAN13Text-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.rewire-icons {
  font-family: 'rewire-icons';
}

@media (max-width: 400px) {
  // remove olark for iSavta (small PC screens)
  #habla_window_div,
  #habla_panel_div {
    display: none !important;
  }
}

// Component styles are injected here through grunt injector:sass task
// injector
// endinjector
