@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.instant-transfer-modal {
  &__img {
    width: 200px;
    height: 123px;
    object-fit: contain;
    background-image: url(./load-instant.svg);
  }
}
