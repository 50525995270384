@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.debit-card-explanation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  align-items: center;

  &__content {
    text-align: center;
    @media only screen and (min-width: 385px) {
      margin: 18px 0;
    }
  }

  &__img {
    background-image: url(./order-card-ilustration.svg);
    display: inline-block;
    width: 211px;
    height: 169px;
    background-repeat: no-repeat;
  }

  &__list {
    display: block;
    margin-top: 13px;
  }

  &__checklist {
    display: inline-block;
  }

  &__checklist-container {
    display: block;
    text-align: center;
    margin-top: 13px;
  }

  &__submit {
    &#{&} {
      align-self: stretch;
      max-width: 1000px;
    }
  }
}
