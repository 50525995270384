@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$kyc-source-of-funds-vertical-padding: 8px;

.kyc-source-of-funds {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__title {
    margin-top: $kyc-source-of-funds-vertical-padding;
    margin-bottom: 10px;
  }

  &__title-text {
    margin-bottom: 15px;
  }

  &__form {
    flex-grow: 1;
    margin-bottom: $kyc-source-of-funds-vertical-padding;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  &__form-part-image {
    margin: auto;
  }
}
