/* patches */

.country-picker .card-body-inner > div > div:first-child {
  padding-bottom: 10px;
  padding-right: 15px !important;
}

.country-picker .card-body-inner > div > div > *:last-child {
  margin-bottom: 10px;
  /*padding-bottom: 10px;*/
}

.CountryPrefixSelect__MenuItem {
  width: 100% !important;
  padding-right: 12px !important;
}

.CountryPrefixSelect__MenuItem > div > div {
  padding: 0 !important;
}

.CountryPrefixSelect [role='menu'] {
  padding: 0 !important;
}
