@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.kyc-processing-onfido {
  height: 100%;

  &__img {
    background-image: url(./upload-file.svg);
    display: inline-block;
    width: 148px;
    height: 154px;
  }
}
