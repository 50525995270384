@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.kyc-personal-information {
  &__fields {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
}
