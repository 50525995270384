@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.activate-debit-card {
  text-align: center;

  rew-text {
    min-height: 2em;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    simple-activation-view {
      height: 100%;
    }
  }
}

.activate-debit-card-fee-modal {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 325px;
  width: 100%;

  @include phone {
    top: 270px;
  }
}
