@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.kyc-modal {
  height: 470px;
  position: relative;

  &__submit-btn {
    display: block;
    text-align: center;

    position: absolute;
    width: 100%;
    bottom: 0;
  }

  @include low-height-device {
    height: auto;

    &__submit-btn {
      position: static;
    }
  }
}
